import { isMandatory, LIB_POL_PROG_CODES } from '../constants'

const initialPolicyDetails = {
  policyNumber: {
    value: '',
    error: false,
    valid: (value) => isValidPolicyNumber(value)
  },
  programCode : {
    value: '',
    error: false,
    valid: (value) => true
  },
  state: {
    value: '',
    error: false,
    valid: (value) => isMandatorySelect(value)
  },
  contactName: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactEmail: {
    value: '',
    error: false,
    valid: (value) => isValidEmail(value)
  }
}

const policyDetails = {
  policyNumber: {
    value: '',
    error: false,
    valid: (value) => isValidPolicyNumber(value)
  },
  programCode : {
    value: '',
    error: false,
    valid: (value) => true
  },
  state: {
    value: '',
    error: false,
    valid: (value) => isMandatorySelect(value)
  },
  contactName: {
    value: '',
    error: false,
    valid: (value) => isMandatory(value)
  },
  contactEmail: {
    value: '',
    error: false,
    valid: (value) => isValidEmail(value)
  },
}

// Given a policy number, checks if it starts with 'H' or 'A' and length 14
// Returns true false otherwise
const isValidPolicyNumber = (val) => {
  if (val.length === 14 && (val.startsWith('H') || val.startsWith('A'))) return true;
  return false;
}

// Validates the select is not blank, returns false if blank and true if not
const isMandatorySelect = (val) => val !== ''

// Validates user e-mail is in the correct format
// Returns true if in correct format, false otherwise
const isValidEmail = (val) => {
  const re =
  /^[(?!.*(?:<|>))[\w,\d,.'-@_+!#$%&'*-/=?^_`{|}~]+@([\w-]+\.)+[\w-]{2,4}]{0,200}$/
  return re.test(val)
}

// Returns a boolean and checks if the details are valid and changes error to false
// Controls whether or not user is able to move to the next page
const validatePolicyDetails = (values, setValues) => {
  let error = false
  Object.keys(values.policyDetails).forEach((key) => {
    const field = values.policyDetails[key]
    if (field.valid) {
      values.policyDetails[key].error = !field.valid(field.value)
      setValues((prevValues) => ({
        ...prevValues
      }))
      if (values.policyDetails[key].error) {
        error = values.policyDetails[key].error
      }
    }
  })
  return !error
}

// Checking URL parameters, and creating variables for the values from the InformationScreen.jsx values. Then creating variables from the values after parsing through the URL. We then compare the values for policy, name, and email with policyDetails from InformationScreen.jsx
const checkUrlParams = (event, values, setValues) => {
  // Get the query string from the current URL
  const queryString = window.location.search;
  // Parse the query string to get the URL parameters
  const urlParams = new URLSearchParams(queryString);

  // Retrieve the current values from the policyDetails object
  const policyNumber = values.policyDetails.policyNumber.value;
  const programCode = values.policyDetails.programCode.value;
  const state = values.policyDetails.state.value;
  const contactName = values.policyDetails.contactName.value;
  const contactEmail = values.policyDetails.contactEmail.value;

  // Retrieve the URL parameters, defaulting to empty strings if not present
  const policy = urlParams.get('policy') || '';
  const programCodeURL = urlParams.get('progCode') || '';
  const stateURL = urlParams.get('state') || '';
  const name = urlParams.get('name') || '';
  const email = urlParams.get('email') || '';

  // Check if the program code from URL is included in the predefined list (LIB_POL_PROG_CODES)
  // Using parseInt with base 10 to ensure the correct interpretation of the number
  const isProgramCodeValid = LIB_POL_PROG_CODES.includes(parseInt(programCodeURL, 10)) || programCodeURL.length === 0;

  // Initialize the completed step list with the current value
  let compStepList = values.completedStepList;

  // Check if the URL parameters match the current form values and the program code is valid
  if (policy === policyNumber && name === contactName && email === contactEmail && state === stateURL && isProgramCodeValid) {
    // If the user is on the initial step, update the completed step list accordingly
    if (values.completedStep === 0) {
      compStepList = [true, false, false];
    }
    // If the user is on the second step, update the completed step list accordingly
    if (values.completedStep === 1) {
      compStepList = [true, true, true];
    }

    // Update the state with the new values
    setValues((prevValues) => ({
      ...prevValues,
      enableContinueInfoPage: true, // Allow progression to the next page
      completedStepList: compStepList // Set the updated step list
    }));
    return true; // Indicate that URL parameters are valid and processed
  }

  return false; // Indicate that URL parameters are not valid or do not match
}

// Stores given input to corresponding details and checks for validation and whether or not to progress to the next page
// Continue button would be enabled if all requirements pass
// URL parameters also update upon changes from input fields
const setPolicyDetailsInput = (event, values, setValues) => {
  let enableContinue = values.enableContinueInfoPage
  let compStepList = values.completedStepList
  const url = new URL(window.location);

  if (event) {
    const { name } = event.target
    let { value } = event.target
    const queryParams = new URLSearchParams(window.location.search);
    const isCustomUrl = queryParams.has('catg');

    if (isCustomUrl && queryParams.get('catg') === 'policy') {
      if (name === 'policyNumber') {
        queryParams.has('policy') ? url.searchParams.set('policy', value) : url.searchParams.append('policy', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'programCode') {
        queryParams.has('progCode') ? url.searchParams.set('progCode', value) : url.searchParams.append('progCode', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'state') {
        queryParams.has('state') ? url.searchParams.set('state', value) : url.searchParams.append('state', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactName') {
        queryParams.has('name') ? url.searchParams.set('name', value) : url.searchParams.append('name', value);
        window.history.pushState(null, '', url.toString());
      }

      if (name === 'contactEmail') {
        queryParams.has('email') ? url.searchParams.set('email', value) : url.searchParams.append('email', value);
        window.history.pushState(null, '', url.toString());
      }
    }

    // Convert to upper case
    if (event.type === 'blur' && name === 'policyNumber' && value.length === 14 ) {
      value = value.toUpperCase();
    }
    
    // If blur event or selector change, validate input field value
    if (event.type === 'blur' || event.target.tagName === 'SELECT') {
      values.policyDetails[name].error = !values.policyDetails[name].valid(value)

      enableContinue = isAllInfoFieldsValid(values)
    }
    values.policyDetails[name].value = value
  } else {
    enableContinue = checkUrlParams(event, values, setValues);
  }
  if (enableContinue === false) {
    compStepList = [false, false, false]
  }
  // Checks to see if user is navigating back to the information screen from File Select or Review
  // If so, re-activates correct breadcrumbs
  if (enableContinue === true && values.completedStep === 0) {
    compStepList = [true, false, false]
  }
  if (enableContinue === true && values.completedStep === 1) {
    compStepList = [true, true, true]
  }
  setValues((prevValues) => ({
    ...prevValues,
    enableContinueInfoPage: enableContinue,
    completedStepList: compStepList
  }))
}

// Checks if the information fields are filled out and valid
// Returns false if any fields have an error or is empty, otherwise returns true
const isAllInfoFieldsValid = (values) => {
  for (const key in values.policyDetails) {
    const property = values.policyDetails[key]
    if (key !== 'programCode') {
      if (property.error === true || property.value === '') {
        return false
      }
    }
  }
  return true
}

export { policyDetails, initialPolicyDetails, isMandatorySelect, validatePolicyDetails, setPolicyDetailsInput, isValidPolicyNumber }
