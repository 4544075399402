import React, { useContext, useEffect } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { Button } from '@lmig/lmds-react-button'
import { FormContext, setSubmitHeader, setSubmitText } from '../FormContext/FormContext'
import FileSelection from '../Pages/FileSelection/FileSelection'
import Review from '../Pages/Review/Review'
import { makeStyles } from '@mui/styles'
import { GridCol, GridRow, Heading, Modal, Popover, IconInfo, IconButton } from '@lmig/lmds-react'
import { STEP_NAMES, HEADINGS, SUBHEADINGS } from '../constants'
import { CircularProgress } from '@mui/material'
import clsx from 'clsx'
import './ClaimInfo.css'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { BodyText } from '@lmig/lmds-react-typography'
import SuccessPage from '../Pages/SuccessPage/SuccessPage'
import { useCheckIsMobile } from '../Hooks/UseCheckIsMobile'
import { logTransactionStart } from '../logger'
import InformationScreen from './InformationScreen'



const steps = [
  STEP_NAMES.STEP_ONE,
  STEP_NAMES.STEP_TWO,
  STEP_NAMES.STEP_THREE,
  STEP_NAMES.STEP_FOUR
]

const headings = [
  HEADINGS.DETAILS.CLAIM,
  HEADINGS.REASON,
  HEADINGS.UPLOAD
]

const subheadings = [
  SUBHEADINGS.INFORMATION,
  SUBHEADINGS.FILE_SELECTION,
  SUBHEADINGS.REVIEW
]

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '##fff',
    borderRadius: '0%',
    color: '#707070',
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '25.6px'
  },

  completed: {
    color: '#28A3AF',
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  active: {
    color: '#343741',
    fontWeight: 700,
    textDecoration: 'none'
  }
}))

const CustomStepIcon = (props) => {
  const classes = useStyles()
  const { active, completed } = props
  const stepIcons = {
    1: 'Information',
    2: 'File selection',
    3: 'Review'
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {stepIcons[String(props.icon)]}
    </div>
  )
}

// Components that handles the pages like a switchboard that stores the state to progress through
// Renders a Stepper on top then a heading, subheading, and component page respective to the current page in view
// 
const Controller = () => {
  const [values, handleSetValues] = useContext(FormContext)
  const headingMargin = useCheckIsMobile() ? '0px' : '17px'
  const margin = useCheckIsMobile() ? '-3px' : '15px'
  const m = (useCheckIsMobile() && values.activeStep === 0) ? '2em' : '.75em' 
  const mB = useCheckIsMobile() ? '2em' : '1em' 

  useEffect(() => {
    logTransactionStart(values);
  },[values.transactionId]);


  const handleSetValuesDetails = (stepDesc, e) => {
    handleSetValues(stepDesc, e)
  }

  // List of all main pages (Information, File Select, Review, and Success) utilized for navigation between them.
  // isFinalStep is utilized to disable rendering of certain elements on Success page
  const pages = [<InformationScreen handleSetValuesDetails={handleSetValuesDetails} values={values}/>, <FileSelection handleSetValuesDetails={handleSetValuesDetails} />, <Review handleSetValuesDetails={handleSetValuesDetails} />, <SuccessPage />]
  const isFinalStep = values.activeStep === steps.length - 1
  const submitProgress = values.submitProgress

  return (
    <Box sx={{ paddingTop: '20px' }}>
      {isFinalStep ? <div></div> :  <Stepper sx={{ marginLeft: margin }} activeStep={values.activeStep}
        connector={<ChevronRightIcon color="action" fontSize="extra-small" sx={{ marginLeft: '-10px' }}/>}>
        {steps.slice(0,3).map((item, index) => (
          <Step key={index} completed={values.completedStepList[index]}>
            <StepLabel
              onClick={(e) => {
                handleSetValues('tabNavigation', index)
              }}
              StepIconComponent={CustomStepIcon}>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      }
      {isFinalStep ? <div></div> :
        <section className="line"></section>
      }
      {isFinalStep ? <div></div> :
        <Box width='10rem' height='1.5em' onClick={(e) => handleSetValues('activeStep', e)}>
          {values.activeStep !== 0 ?
            <BodyText type="default-medium" className='backButton' style={{ marginTop: '61px', marginLeft: headingMargin, marginBottom: '-50px' }}>
              <p> &lt; Back </p>
            </BodyText>
            : ''}
        </Box>
      }
      <GridRow>
        <GridCol>
          <GridRow>
            <GridCol>
              {isFinalStep ? <div></div> :
                <Heading align='left'
                  style={{
                    height: '44px',
                    fontFamily: 'Roboto', maxWidth: 'inherit', paddingTop: '20px', marginLeft: headingMargin, marginBottom: m,
                    fontStyle: 'normal',
                    fontWeight: '300',
                    fontSize: '36px',
                    lineHeight: '43px'
                  }} >
                  {headings[values.activeStep]}
                </Heading>
              }
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol>
              <Heading align='left'
                style={{
                  fontFamily: 'Roboto', maxWidth: 'inherit', marginTop: '2em', marginBottom: mB, marginLeft: headingMargin, fontStyle: 'normal',
                  fontWeight: '400',
                  height: '26px',
                  fontSize: '16px',
                  lineHeight: '26px'
                }} className='subheading-largescreen'>
                {subheadings[values.activeStep]}
                {values.activeStep === 0 ?
                  <Popover
                    direction="below"
                    trigger={<IconButton background="transparent" style={{padding:'0px', margin:'0px'}}><IconInfo color="teal" size="16" style={{padding:'0px', margin:'0px'}} /></IconButton>}
                  >
                    <BodyText style={{
                      fontWeight: '300'
                    }}>
                      <p>Select <strong>Property/Casualty Claim</strong> to upload documents related to a personal home or auto policy claim.</p>
                      <p>Select <strong>Liberty Policy or Policy Change</strong> to upload documents related to a Liberty personal home or auto policy or a change to that policy.</p>
                      <p>Select <strong>Liberty Insurance Quote</strong> to upload documents related to a Liberty quote (you do not yet have a policy).</p>
                      <p>Select <strong>Liberty Small Commercial</strong> to upload documents related to a Liberty small commercial policy or a change to that policy.</p>
                      <p>Select <strong>Safeco Policy or Policy Change</strong> to upload documents related to a Safeco personal home or auto policy or a change to that policy.</p>
                    </BodyText>
                  </Popover>
                  : ''}
              </Heading>
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
      <div style={{ marginBottom: '70px' }}>
        {pages[values.activeStep]}
      </div>
    </Box>
  )
}

export default Controller
