import getEnv from '../getEnv'



export const getEmailForSafecoTransLevel = (progCode) => {
  let emailForSafeco = '';
  if (getEnv('ENV') === 'production') {
    const SFPlumbPEP = ['Plumbing Pre-Endorsement Verification'];
    const SFRoofPEP = ['Roof Pre-Endorsement Verification'];

    if (SFPlumbPEP.includes(progCode)) emailForSafeco = 'SFPlumbPEP@safeco.com'; 
    if (SFRoofPEP.includes(progCode)) emailForSafeco = 'SFRoofPEP@safeco.com';
  } else {
    emailForSafeco = 'docsolpipelinecrew@libertymutual.com'; /* default non-prod email*/
  }
  return emailForSafeco;
}
